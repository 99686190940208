// Content/_Source/Utilities/Index.js

import FlatToNested from "flat-to-nested";
import { registerNamespace } from '_LbCommon/RegisterNamespace';

export default function _flatToNested(data, config) {

	let _converter = new FlatToNested(config);
	return _converter.convert(data);
};

registerNamespace('LB.Utilities');
LB.Utilities.FlatToNested = _flatToNested;
